import React, { useEffect, useState } from "react";
import { Typography, TextField, Button } from "@mui/material";

const EditableText = ({ initialText, onSave, onCancel }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [originalText, setOriginalText] = useState(initialText);

  useEffect(() => {
    setText(initialText);
    setOriginalText(initialText);
  }, [initialText]);

  const handleSave = () => {
    onSave(text);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setText(originalText);
    setIsEditing(false);
    onCancel(); // You can use onCancel to perform any additional cleanup or actions
  };

  return (
    <div>
      {isEditing ? (
        <div>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ marginTop: 10 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancel}
            style={{ marginTop: 10, marginLeft: 10 }}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <div
          onClick={() => setIsEditing(true)}
          style={{
            cursor: "pointer",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              // textDecoration: "underline #4a79c9 dashed",
              color: "#4a79c9",
            }}
          >
            {text}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default EditableText;
