import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  TextField,
  IconButton,
  Popover,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import axiosServices from "../../services/axios";
import Loader from "../../components/loader";

function QuestionList({
  questions,
  questionSelected,
  assessments,
  selectedSubject,
}) {
  const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState(questions);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [categories, setCategories] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Open Popover
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close Popover
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "skill-filter-popover" : undefined;

  const getAllCategories = async () => {
    setIsLoading(true);
    try {
      const response = await axiosServices.get(
        `/categories/${selectedSubject}`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    selectedSubject && getAllCategories();
  }, [selectedSubject]);

  useEffect(() => {
    let preSelectedQuestion = JSON.parse(
      localStorage.getItem("selectedQuestion")
    );
    if (preSelectedQuestion) {
      let selectedQuestionExistsInTheList = questions.find(
        (question) => question.id == preSelectedQuestion.id
      );
      if (selectedQuestionExistsInTheList) {
        setSelectedQuestion(selectedQuestionExistsInTheList);
      } else {
        setSelectedQuestion(questions[0]);
      }
    }
  }, [questions]);

  useEffect(() => {
    selectedQuestion && questionSelected(selectedQuestion);
    selectedQuestion &&
      localStorage.setItem(
        "selectedQuestion",
        JSON.stringify(selectedQuestion)
      );
  }, [selectedQuestion]);

  const handleQuestionClick = (event, question) => {
    setSelectedQuestion(question);
    questionSelected(question);
  };

  const getBackgroundColour = (question) => {
    let asstId, color;
    if (question.Assessments && question.Assessments.length > 0) {
      asstId = question.Assessments[0].id;
    }
    if (assessments && assessments.length > 0) {
      assessments.forEach((asst) => {
        if (asst.id == asstId) {
          color = asst.color;
        }
      });
    }
    return color || "none";
  };

  // Filter questions based on the search term and selected skills
  useEffect(() => {
    const results = questions.filter((question) => {
      const matchesSearchTerm = question.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const matchesSelectedSkills =
        selectedSkills.length === 0 ||
        question.Tags.some((tag) => selectedSkills.includes(tag.id));

      return matchesSearchTerm && matchesSelectedSkills;
    });
    setFilteredQuestions(results);
  }, [searchTerm, selectedSkills, questions]);

  const handleSkillToggle = (skillId) => {
    setSelectedSkills((prevSelected) =>
      prevSelected.includes(skillId)
        ? prevSelected.filter((id) => id !== skillId)
        : [...prevSelected, skillId]
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      {questions && (
        <Box sx={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={"h5"}>Question List</Typography>

            {/* Filter Button */}
            <IconButton aria-label="filter" onClick={handleFilterClick}>
              <FilterListIcon />
            </IconButton>
          </Box>

          {/* Search Bar */}
          <TextField
            label="Search Questions"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <List
            component="nav"
            aria-label="questions"
            sx={{ maxHeight: "500px", overflow: "auto" }}
          >
            {filteredQuestions.map((question, index) => (
              <ListItemButton
                sx={{
                  border: question.isVerified ? "none" : "1px solid orange",
                  background: getBackgroundColour(question),
                }}
                key={index}
                selected={selectedQuestion?.id === question.id}
                onClick={(event) => handleQuestionClick(event, question)}
              >
                <ListItemText primary={`${index + 1}. ${question.title}`} />
              </ListItemButton>
            ))}
          </List>

          {/* Popover for Skill Filter */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box sx={{ padding: 2 }}>
              <Typography variant="h6">Filter by Skills</Typography>
              {categories?.Categories?.map((category) => (
                <Box key={category.id} sx={{ marginTop: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {category.name}
                  </Typography>
                  <FormGroup>
                    {category?.Tags?.map((skill) => (
                      <FormControlLabel
                        key={skill.id}
                        control={
                          <Checkbox
                            checked={selectedSkills.includes(skill.id)}
                            onChange={() => handleSkillToggle(skill.id)}
                          />
                        }
                        label={skill.name}
                      />
                    ))}
                  </FormGroup>
                </Box>
              ))}
            </Box>
          </Popover>
        </Box>
      )}
    </>
  );
}

QuestionList.propTypes = {
  questions: PropTypes.array,
  questionSelected: PropTypes.func,
  assessments: PropTypes.array,
  selectedSubject: PropTypes.number,
};

export default QuestionList;
