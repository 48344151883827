import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { TextField, Typography, Container } from "@mui/material";
import AuthService from "../../services/auth";
import axiosServices from "../../services/axios";
import { LoadingButton } from "@mui/lab";

const theme = createTheme();

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
}));

const StyledForm = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 400,
  marginTop: theme.spacing(3),
}));

const StyledSubmitButton = styled(LoadingButton)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    // In this example, we'll just check if the username and password are not empty
    if (username.trim() !== "" && password.trim() !== "") {
      // Perform login logic here
      try {
        const response = await axiosServices.post("/login", {
          input: username,
          password,
        });
        setErrors("");
        const { serviceToken, user } = response.data;
        // store the token in the browser
        AuthService.login(serviceToken, user);
        // Navigate to the dashboard page
        navigate("/dashboard");
      } catch (error) {
        setErrors(error.message);
      } finally {
        setIsLoading(false);
        console.log("close loader");
      }
    } else {
      setErrors("Please enter a valid username and password.");
    }
  };

  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = AuthService.isAuthenticated();
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer component="main" maxWidth="xs">
        <StyledForm>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            {errors && (
              <div style={{ textAlign: "center", color: "red" }}>{errors}</div>
            )}
            <StyledSubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Login
            </StyledSubmitButton>
          </form>
        </StyledForm>
      </StyledContainer>
    </ThemeProvider>
  );
}

export default LoginPage;
