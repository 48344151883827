import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";

const CreateAssessmentModal = ({
  open,
  handleClose,
  handleCreate,
  clearFields,
  assessment,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [enableTimer, setEnableTimer] = useState(false);
  const [errors, setErrors] = useState({});

  const convertToDateTimeLocal = (isoDate) => {
    const date = new Date(isoDate);

    // Extract year, month, day, hours, and minutes
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return in the format yyyy-MM-ddThh:mm
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (clearFields) {
      setTitle("");
      setDescription("");
      setDueDate("");
      setEnableTimer(false);
      setErrors(false);
    }
  }, [clearFields]);

  useEffect(() => {
    if (assessment) {
      setTitle(assessment.title);
      setDescription(assessment.description);
      setDueDate(convertToDateTimeLocal(assessment.dueDate));
      setEnableTimer(assessment.enableTimer);
    } else {
      setTitle("");
      setDescription("");
      setDueDate("");
      setEnableTimer("");
    }
  }, [assessment]);

  // Handle form submission
  const handleSubmit = () => {
    const validationErrors = {};

    if (!title) validationErrors.title = "Title is required";
    if (!description) validationErrors.description = "Description is required";
    if (!dueDate) validationErrors.dueDate = "Due Date is required";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set form validation errors
    } else {
      // Reset errors and send data
      setErrors({});
      handleCreate({
        id: assessment?.id,
        title,
        description,
        dueDate,
        enableTimer,
        isUpdate: assessment ? true : false,
      }); // Send form data to parent
      handleClose(); // Close the modal
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Assessment</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          <TextField
            label="Title"
            fullWidth
            required
            margin="dense"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            label="Description"
            fullWidth
            required
            margin="dense"
            multiline
            minRows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={!!errors.description}
            helperText={errors.description}
          />
          <TextField
            label="Due Date"
            type="datetime-local"
            fullWidth
            required
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            error={!!errors.dueDate}
            helperText={errors.dueDate}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={enableTimer}
                onChange={(e) => setEnableTimer(e.target.checked)}
              />
            }
            label="Enable Timer"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAssessmentModal;
