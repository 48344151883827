import axiosServices from "./axios";

class AuthService {
  static isAuthenticated() {
    return !!localStorage.getItem("isLoggedIn");
  }

  static login(serviceToken, user) {
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem(
      "user",
      JSON.stringify({ serviceToken: serviceToken, user: user })
    );
    axiosServices.defaults.headers.common.Authorization = `${serviceToken}`;
  }

  static logout() {
    localStorage.clear();
    delete axiosServices.defaults.headers.common.Authorization;
  }
}

const user = JSON.parse(localStorage.getItem("user"));
if (user && user.serviceToken) {
  axiosServices.defaults.headers.common.Authorization = `${user.serviceToken}`;
}

export default AuthService;
