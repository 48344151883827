import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import axiosServices from "../../services/axios";
import EditableText from "../../components/editable-text";
import Loader from "../../components/loader";

function SelectedQuestion({
  selectedQuestion,
  selectedSubjectId,
  refreshQuestions,
  topics,
  aiContext,
  assessments,
}) {
  const [categories, setCategories] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const { user } = JSON.parse(localStorage.getItem("user"));
  const [remarks, setRemarks] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState();
  const [newReason, setNewReason] = useState("");

  useEffect(() => {
    if (selectedQuestion) {
      if (selectedQuestion.Options && selectedQuestion.Options.length) {
        selectedQuestion.Options.sort((option1, option2) => {
          return option1.id - option2.id;
        });
        let selectedOptionId = "";
        selectedQuestion.Options.forEach((option) => {
          if (option.value == "1") {
            selectedOptionId = option.id;
          }
        });
        setCorrectAnswer(selectedOptionId);
      }
      selectedQuestion?.id && getReportsForQuestion();
      setSelectedTopic(selectedQuestion.topicId || "");
      setRemarks(selectedQuestion.remarks || "");
      setIsVerified(selectedQuestion.isVerified || false);
      selectedQuestion?.id && prepareCategories();
    }
  }, [selectedQuestion]);

  const prepareCategories = () => {
    const categoriesMap = {};
    const tagsData = selectedQuestion?.Tags;
    if (tagsData.length) {
      tagsData.forEach((tag) => {
        const { Category, name } = tag;

        // If category is not yet added, initialize it with id and name
        if (!categoriesMap[Category.id]) {
          categoriesMap[Category.id] = {
            id: Category.id,
            name: Category.name,
            tags: [],
          };
        }

        // Add the tag name to the corresponding category
        categoriesMap[Category.id].tags.push(name);
      });
    }
    setCategories(Object.values(categoriesMap));
  };

  const getReportsForQuestion = async () => {
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/reports/${selectedQuestion?.id}`
      );
      if (response.data) {
        setReportData(response.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (type, value) => {
    let updateValues = {
      verifiedBy: user.id,
    };
    switch (type) {
      case "title":
        updateValues.title = value;
        break;
      case "topicId":
        updateValues.topicId = value;
        break;
      case "remarks":
        updateValues.remarks = value;
        break;
      case "isVerified":
        updateValues.isVerified = value;
        break;
      case "solution":
        updateValues.solution = value;
        break;
      default:
        break;
    }
    try {
      setIsLoading(true);
      const response = await axiosServices.put(
        `/questions/${selectedQuestion.id}`,
        updateValues
      );
      refreshQuestions();
    } catch (error) {
      console.error("Error updating question:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSolutionToQuestion = async () => {
    let result = "";
    try {
      let correctAnswer;
      let input;
      let defaultProgram, defaultLanguage;
      if (selectedQuestion.type == "SINGLE") {
        correctAnswer = selectedQuestion?.Options.find(
          (option) => option.value == 1
        ).title;
        // form the prompt
        let userPrompt = `Act as the best teacher in the world ${
          aiContext ? aiContext : ""
        } and explain the answer to this question step by step, as if you were explaining it to a 10-year-old. Very Important rules to follow - 1. Don't use LaTeX formatting in the explanation. 2. Give me the complete explanation in pure HTML using mathematical symbols, <sup> for superscripts and <sub> for subscripts where appropriate. 3. Never mention titles like - "Here is the explanation for 10 year old, formatted in HTML". 4. Don't use header tags below h3 & div tag. For reference - Here is an example format: <h3>Solution Title</h3><ol><li><b>Step 1: Title</b><p>Description</p></li><li><b>Step 2: Title</b><p>Description</p><ul><li>Point 1</li><li>Point 2</li></ul></li></ol>`;
        // console.log(userPrompt);
        input = {
          questionId: selectedQuestion.id,
          system: `Question: ${selectedQuestion?.title}. Answer: ${correctAnswer}.`,
          user: userPrompt,
        };
      } else {
        // if question type is program, this we dont send an answer to the question.
        // this is because there can be a default program which is wrong and which need to be corrected.
        correctAnswer = "";
        if (selectedQuestion.props) {
          defaultLanguage = JSON.parse(selectedQuestion.props).language;
          if (JSON.parse(selectedQuestion.props).defaultProgram) {
            defaultProgram = JSON.parse(selectedQuestion.props).defaultProgram;
          }
        }
        let systemPrompt = `Question: ${selectedQuestion?.title}`;
        if (defaultProgram) {
          systemPrompt += `${defaultProgram}`;
        }
        let userPrompt = `Act as the best teacher in the world ${
          aiContext ? aiContext : ""
        } and explain the answer to this question step by step, as if you were explaining it to a 10-year-old. Write the correct program and explain considering the programming language as ${defaultLanguage}. Very Important rules to follow - 1. Don't use LaTeX formatting in the explanation. 2. Give me the complete explanation in pure HTML using mathematical symbols, <sup> for superscripts and <sub> for subscripts where appropriate. 3. Never mention titles like - "Here is the explanation for 10 year old, formatted in HTML". 4. Don't use header tags below h3 & div tag. 5. Always display code with proper indentation inside the <pre> & <code> tags. For reference - Here is an example format: <h3>Solution Title</h3><ol><li><b>Step 1: Title</b><p>Description</p></li><li><b>Step 2: Title</b><p>Description</p><ul><li>Point 1</li><code></code></ul></li></ol>`;
        // console.log(userPrompt);
        input = {
          questionId: selectedQuestion.id,
          system: systemPrompt,
          user: userPrompt,
        };
      }
      const responseFromOpenAI = await axiosServices.post(
        "/completions",
        input
      );
      result = responseFromOpenAI.data.response;
    } catch (error) {
      console.log(error);
    } finally {
      console.log("done");
      return result;
    }
  };

  const handleCancel = () => {
    // Optional: You can perform any cleanup actions here if needed
    console.log("Edit canceled!");
  };

  const handleOptionSave = async (newOptionText, option) => {
    try {
      setIsLoading(true);
      const response = await axiosServices.put(`/options/${option.id}`, {
        title: newOptionText,
      });
      await handleSave("options", null);
      refreshQuestions();
    } catch (error) {
      console.error("Error updating option text:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionCancel = () => {
    console.log("Edit cancelled");
  };

  const handleAnswerChange = async (event) => {
    setCorrectAnswer(event.target.value);
    let newOptions = JSON.parse(JSON.stringify(selectedQuestion.Options));
    newOptions.map((option) => {
      option.value = option.id == event.target.value ? 1 : 0;
    });
    try {
      setIsLoading(true);
      const response = await axiosServices.put(
        `/options/update/values`,
        newOptions
      );
      await handleSave("options", null);
      refreshQuestions();
    } catch (error) {
      console.error("Error updating option values:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
    handleSave("topicId", event.target.value);
  };

  const handleVerifiedChange = async (event) => {
    setIsVerified(event.target.checked);
    await handleSave("isVerified", event.target.checked);
    if (event.target.checked) {
      let solution = await updateSolutionToQuestion();
    }
  };

  const handleAddReport = async () => {
    const newReport = {
      studentId: user.id,
      reportedItemId: selectedQuestion.id,
      reportedItemType: "QUESTION",
      reason: newReason,
    };

    try {
      setIsLoading(true);
      // Example API call using axios (replace with your actual API endpoint)
      const response = await axiosServices.post("/report", newReport);

      // After successful post request, you can update the data to display the new report
      setReportData((prevData) => [
        ...prevData,
        {
          id: response.data.id, // assuming the API response contains the new report ID
          reportedItemId: newReport.reportedItemId,
          reportedItemType: newReport.reportedItemType,
          reason: newReport.reason,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          studentId: newReport.studentId,
        },
      ]);

      // Clear the text field after adding
      setNewReason("");
    } catch (error) {
      console.error("Error adding report:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssessmentChange = async (event) => {
    let input = {
      classification: "",
      assessmentId: "",
      questionId: selectedQuestion?.id,
    };
    if (event.target.value != 0) {
      // move question to assessment with value assessmentId
      input.classification = "ASSESSMENT";
      input.assessmentId = event.target.value;
    } else {
      // move question to practice
      input.classification = "PRACTICE";
      input.assessmentId = null;
    }
    try {
      setIsLoading(true);
      const response = await axiosServices.post(`/questions/move`, input);
    } catch (error) {
      console.error("Error updating option values:", error);
    } finally {
      refreshQuestions();
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container spacing={1} px={2.5}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          borderRight={"1px solid #eee"}
          pr={2}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h5" mb={2}>
              Selected Question (ID: {selectedQuestion.id})
            </Typography>
          </Stack>
          <EditableText
            initialText={selectedQuestion.title}
            onSave={(newText) => handleSave("title", newText)}
            onCancel={handleCancel}
          />
          <Grid container>
            <Grid item xs={8}>
              <ol style={{ paddingLeft: 20 }} type="A">
                {selectedQuestion &&
                  selectedQuestion.Options &&
                  selectedQuestion.Options.length > 0 &&
                  selectedQuestion.Options.map((option, index) => (
                    <li key={index}>
                      <EditableText
                        initialText={option.title}
                        onSave={(text) => handleOptionSave(text, option)}
                        onCancel={handleOptionCancel}
                      />
                    </li>
                  ))}
              </ol>
              <FormGroup sx={{ mb: 2 }}>
                <FormControlLabel
                  control={<Checkbox checked={isVerified} />}
                  label="Verified?"
                  onChange={(e) => handleVerifiedChange(e)}
                />
              </FormGroup>
              {selectedQuestion &&
                selectedQuestion.Options &&
                selectedQuestion.Options.length > 0 && (
                  <Grid container>
                    <Grid item display={"flex"} alignItems={"center"} mr={2}>
                      Correct Answer:
                    </Grid>
                    <Grid item>
                      <Box sx={{ minWidth: 200 }}>
                        <FormControl fullWidth>
                          <InputLabel id="answer-select-label">
                            Answer
                          </InputLabel>
                          <Select
                            labelId="answer-select-label"
                            id="answer-select"
                            value={correctAnswer}
                            label="Answer"
                            onChange={handleAnswerChange}
                          >
                            {selectedQuestion &&
                              selectedQuestion.Options &&
                              selectedQuestion.Options.length > 0 &&
                              selectedQuestion.Options.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              {assessments?.length > 0 && (
                <>
                  <Grid container mt={3}>
                    <Grid item>
                      <FormGroup sx={{ mb: 2 }}>
                        <RadioGroup
                          row
                          aria-labelledby="radio-group-label"
                          name="row-radio-buttons-group"
                          onChange={handleAssessmentChange}
                          value={
                            selectedQuestion?.classification === "PRACTICE"
                              ? 0
                              : selectedQuestion?.Assessments
                              ? selectedQuestion.Assessments[0]?.id
                              : null
                          }
                        >
                          <FormControlLabel
                            key={0}
                            value={0}
                            control={<Radio />}
                            label={"Practice"}
                          />
                          {assessments.map((assessment) => {
                            return (
                              <FormControlLabel
                                key={assessment.id}
                                value={assessment.id}
                                control={<Radio />}
                                label={assessment.title}
                                sx={{ color: assessment.color }}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <div style={{ paddingLeft: "8px" }}>
                <Typography variant="h5" mb={2}>
                  Skills
                </Typography>
                {categories.length > 0 &&
                  categories.map((category, index) => (
                    <Grid container width={"100%"} key={index}>
                      <Grid item width={"100%"}>
                        <Typography variant="h6">{category.name}</Typography>
                        <List disablePadding>
                          {category.tags.map((tag) => {
                            return <ListItemText>{tag}</ListItemText>;
                          })}
                        </List>
                        <Divider style={{ marginBottom: "10px" }} />
                      </Grid>
                    </Grid>
                  ))}
              </div>
            </Grid>
          </Grid>

          {selectedQuestion &&
            selectedQuestion.Options &&
            selectedQuestion.Options.length > 0 && (
              <>
                <Grid container mt={3}>
                  <Grid item display={"flex"} alignItems={"center"} mr={2}>
                    Move this question to another topic:
                  </Grid>
                  <Grid item>
                    <Box sx={{ minWidth: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel id="answer-select-label">Topic</InputLabel>
                        <Select
                          labelId="topic-select-label"
                          id="topic-select"
                          value={selectedTopic}
                          label="Topic"
                          onChange={handleTopicChange}
                        >
                          {topics.map((topic) => (
                            <MenuItem key={topic.id} value={topic.id}>
                              {topic.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Typography sx={{ mt: 3 }}>Remarks</Typography>
                <TextField
                  value={remarks}
                  fullWidth
                  multiline
                  rows={4}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                <Typography sx={{ mt: 3, mb: 1 }}>
                  Comments from Reporters
                </Typography>
                {reportData && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Reason</TableCell>
                          <TableCell>Student ID</TableCell>
                          <TableCell>Created At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportData.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.reason}</TableCell>
                            <TableCell>{item.studentId}</TableCell>
                            <TableCell>
                              {new Date(item.createdAt).toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={4}>
                            <TextField
                              label="Enter Reason"
                              value={newReason}
                              onChange={(e) => setNewReason(e.target.value)}
                              fullWidth
                            />
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAddReport}
                              disabled={!newReason.trim()} // Disable button if input is empty
                            >
                              Add Report
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => handleSave("remarks", remarks)}
                >
                  Save
                </Button>
              </>
            )}
        </Grid>
      </Grid>
    </>
  );
}

SelectedQuestion.propTypes = {
  selectedQuestion: PropTypes.object,
  selectedSubjectId: PropTypes.number,
  refreshQuestions: PropTypes.func,
  topics: PropTypes.array,
  aiContext: PropTypes.string,
  assessments: PropTypes.array,
};

export default SelectedQuestion;
