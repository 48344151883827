import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Drawer, List, ListItem, Tooltip } from "@mui/material";
import { deepOrange, green, purple } from "@mui/material/colors";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: "100%",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "56px",
    marginTop: theme.spacing(8),
  },
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.dark : "transparent",
  color: selected ? theme.palette.primary.dark : "inherit",
}));

function DashboardSideMenu({ selectedMenuItem, onMenuItemClick }) {
  const user = JSON.parse(localStorage.getItem("user")).user;

  return (
    <StyledDrawer variant="permanent">
      <List disablePadding>
        <StyledListItem
          button
          key="Questions"
          selected={selectedMenuItem === "questions"}
          onClick={() => onMenuItemClick("questions")}
          sx={{
            paddingLeft: "12px",
          }}
        >
          <Tooltip title="Questions">
            <Avatar sx={{ bgcolor: deepOrange[500], width: 30, height: 30 }}>
              Q
            </Avatar>
          </Tooltip>
        </StyledListItem>

        {(user.id == 1 || user.id == 2) && (
          <StyledListItem
            button
            key="Videos & Documents"
            selected={selectedMenuItem === "videos"}
            onClick={() => onMenuItemClick("videos")}
            sx={{
              paddingLeft: "12px",
            }}
          >
            <Tooltip title="Videos & Documents">
              <Avatar sx={{ bgcolor: purple[500], width: 30, height: 30 }}>
                V
              </Avatar>
            </Tooltip>
          </StyledListItem>
        )}

        {(user.id == 1 || user.id == 2) && (
          <StyledListItem
            button
            key="Assessments"
            selected={selectedMenuItem === "assessments"}
            onClick={() => onMenuItemClick("assessments")}
            sx={{
              paddingLeft: "12px",
            }}
          >
            <Tooltip title="Assessments">
              <Avatar sx={{ bgcolor: green[500], width: 30, height: 30 }}>
                A
              </Avatar>
            </Tooltip>
          </StyledListItem>
        )}
      </List>
    </StyledDrawer>
  );
}

export default DashboardSideMenu;
