import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Snackbar,
  Tooltip,
} from "@mui/material";
import DropdownSelector from "../../components/dropdown-selector";
import { useState } from "react";
import EditableText from "../../components/editable-text";
import { AssignmentOutlined, Delete, OpenInNew } from "@mui/icons-material";
import axiosServices from "../../services/axios";
import CreateMediaModal from "./create-media-modal";
const mediaSX = {
  width: "100%",
  height: 125,
  borderRadius: "12px",
};

function Videos() {
  const [selectedTopic, setSelectedTopic] = useState("");
  const [videos, setVideos] = useState();
  const [documents, setDocuments] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openCreateMediaModal, setOpenCreateMediaModal] = useState(false);
  const [createMediaType, setCreateMediaType] = useState("VIDEO");

  const subjectSelected = (subject) => {
    console.log(subject);
  };

  const chapterSelected = (chapter) => {
    console.log(chapter);
    setSelectedTopic("");
  };

  const getMediaForTopic = async (topicId) => {
    try {
      const response = await axiosServices.get(`/media/${topicId}`);
      if (response.data && response.data.length > 0) {
        let vids = [],
          docs = [];
        response.data.forEach((item) => {
          if (item.type == "VIDEO") {
            vids.push(item);
          } else {
            docs.push(item);
          }
        });
        setVideos(vids);
        setDocuments(docs);
      } else {
        setVideos([]);
        setDocuments([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log("done");
    }
  };

  const topicSelected = async (topic) => {
    console.log(topic);
    setSelectedTopic(topic);
    getMediaForTopic(topic.id);
  };

  const handleCancel = () => {
    // Optional: You can perform any cleanup actions here if needed
    console.log("Edit canceled!");
  };

  const handleSave = async (media, nameOrURL, newText) => {
    let input = {
      name: nameOrURL == "name" ? newText : media.name,
      url: nameOrURL == "url" ? newText : media.url,
    };
    try {
      const response = await axiosServices.put(`/media/${media.id}`, input);
      if (response.status == 200) {
        setSnackbarMessage("Update Successful");
        setSnackbarOpen(true);
        getMediaForTopic(selectedTopic.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log("done");
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarMessage("");
    setSnackbarOpen(false);
  };

  const deleteVideo = async (media) => {
    let confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      try {
        const response = await axiosServices.delete(`/media/${media.id}`);
        if (response.status == 200) {
          setSnackbarMessage("Delete Successful");
          setSnackbarOpen(true);
          getMediaForTopic(selectedTopic.id);
        }
      } catch (error) {
        console.log(error);
      } finally {
        console.log("done");
      }
    }
  };

  const openAddMediaModal = (mediaType) => {
    setCreateMediaType(mediaType);
    setOpenCreateMediaModal(true);
  };

  const closeAddMediaModal = () => {
    setOpenCreateMediaModal(false);
    getMediaForTopic(selectedTopic.id);
  };

  return (
    <>
      <DropdownSelector
        onSubjectSelected={subjectSelected}
        onChapterSelected={chapterSelected}
        onTopicSelected={topicSelected}
      />
      {selectedTopic && (
        <Grid container mt={0} spacing={3}>
          <Grid item xs={6}>
            <Card>
              <CardHeader
                title={"Videos"}
                action={
                  <Button
                    variant={"contained"}
                    color="primary"
                    size="small"
                    onClick={() => {
                      openAddMediaModal("VIDEO");
                    }}
                  >
                    Add Video
                  </Button>
                }
              ></CardHeader>
              <CardContent>
                <List>
                  {videos?.length
                    ? videos?.map((video, index) => (
                        <Grid
                          container
                          alignItems="start"
                          spacing={2}
                          key={index}
                          marginBottom={2}
                        >
                          <Grid item xs={4}>
                            <CardMedia
                              component="iframe"
                              src={video.url}
                              title="image"
                              sx={mediaSX}
                              allowFullScreen
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <div>
                                  <EditableText
                                    initialText={video.name}
                                    onSave={(newText) =>
                                      handleSave(video, "name", newText)
                                    }
                                    onCancel={handleCancel}
                                  />

                                  <EditableText
                                    initialText={video.url}
                                    onSave={(newText) =>
                                      handleSave(video, "url", newText)
                                    }
                                    onCancel={handleCancel}
                                  />
                                </div>
                                <div>
                                  <Tooltip title={"Delete Video"}>
                                    <IconButton
                                      variant="contained"
                                      size="small"
                                      color={"error"}
                                      onClick={() => {
                                        deleteVideo(video);
                                      }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    : "No videos found. Add videos to see here."}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader
                title={"Documents"}
                action={
                  <Button
                    variant={"contained"}
                    color="primary"
                    size="small"
                    onClick={() => {
                      openAddMediaModal("DOCUMENT");
                    }}
                  >
                    Add Document
                  </Button>
                }
              ></CardHeader>
              <CardContent>
                <List>
                  {documents?.length > 0
                    ? documents?.map((document, index) => {
                        return (
                          <ListItem key={index}>
                            <Grid
                              container
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item xs={10}>
                                <EditableText
                                  initialText={document.name}
                                  onSave={(newText) =>
                                    handleSave(document, "name", newText)
                                  }
                                  onCancel={handleCancel}
                                />

                                <EditableText
                                  initialText={document.url}
                                  onSave={(newText) =>
                                    handleSave(document, "url", newText)
                                  }
                                  onCancel={handleCancel}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Tooltip title={"View Document"}>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                      window.open(document.url, "_blank");
                                    }}
                                  >
                                    <OpenInNew />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete Document"}>
                                  <IconButton
                                    variant="contained"
                                    size="small"
                                    color={"error"}
                                    onClick={() => {
                                      deleteVideo(document);
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      })
                    : "No documents found. Add documents to see here."}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
      <CreateMediaModal
        open={openCreateMediaModal}
        onClose={closeAddMediaModal}
        type={createMediaType}
        topicId={selectedTopic.id}
      />
    </>
  );
}

export default Videos;
