import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/ExitToApp";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: "100%",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
}));

const StyledLogoutButton = styled(IconButton)(({ theme }) => ({
  color: "white",
}));

function DashboardHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the isLoggedIn value from localStorage
    localStorage.removeItem("isLoggedIn");
    // Navigate to the login page
    navigate("/login");
  };

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <StyledTitle variant="h6">Welcome</StyledTitle>
        <StyledLogoutButton onClick={handleLogout}>
          <LogoutIcon />
        </StyledLogoutButton>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default DashboardHeader;
