import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardHeader from "../../components/header";
import DashboardSideMenu from "../../components/sidebar";
import Chapters from "../chapters";
import Topics from "../topics";
import Questions from "../questions";
import Subjects from "../subjects";
import Videos from "../videos";
import Assessments from "../assessments";

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
}));

const StyledContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
}));

const StyledSidebar = styled("div")(({ theme }) => ({
  width: "56px",
}));

const StyledMainContent = styled("main")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
}));

function DashboardPage() {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("questions");

  // Check if the user is logged in
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  if (!isLoggedIn) {
    // If the user is not logged in, navigate to the login page
    navigate("/login");
    return null;
  }

  const getContent = () => {
    let content;
    switch (selectedMenuItem) {
      case "subjects":
        content = <Subjects />;
        break;
      case "chapters":
        content = <Chapters />;
        break;
      case "topics":
        content = <Topics />;
        break;
      case "videos":
        content = <Videos />;
        break;
      case "questions":
        content = <Questions />;
        break;
      case "assessments":
        content = <Assessments />;
        break;
      default:
        content = null;
    }
    return content;
  };

  return (
    <StyledRoot>
      <DashboardHeader />
      <StyledContent>
        <StyledSidebar>
          <DashboardSideMenu
            selectedMenuItem={selectedMenuItem}
            onMenuItemClick={(menuItem) => setSelectedMenuItem(menuItem)}
          />
        </StyledSidebar>
        <StyledMainContent>{getContent()}</StyledMainContent>
      </StyledContent>
    </StyledRoot>
  );
}

export default DashboardPage;
