import { Button, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import axiosServices from "../../services/axios";

function CreateMediaModal({ open, onClose, type = "VIDEO", topicId }) {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  const handleCreate = async () => {
    let input = {
      topicId,
      name,
      url: formatURL(url),
      type,
    };
    try {
      const response = await axiosServices.post(`/media`, input);
      if (response.status == 200) {
        console.log("done");
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log("done");
      setName("");
      setUrl("");
      onClose();
    }
  };

  const handleDiscard = () => {
    setName("");
    setUrl("");
    console.log("Discarding...");
    onClose();
  };

  const formatURL = (url) => {
    return `https://www.youtube.com/embed/${url}`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          padding: "2rem",
          borderRadius: "8px",
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Create a {type}
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{ marginTop: "20px" }}
        >
          <div style={{ marginTop: "1rem" }}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <TextField
              label="Youtube Video Id"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              fullWidth
              required
            />
          </div>
          <div style={{ marginTop: "2rem", textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              style={{ marginRight: "1rem" }}
            >
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDiscard}
            >
              Discard
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

CreateMediaModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  topicId: PropTypes.number,
};

export default CreateMediaModal;
