import React, { useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import axiosServices from "../../services/axios";
import Loader from "../../components/loader";
import { Add, Delete, Edit } from "@mui/icons-material";
import CreateAssessmentModal from "../../components/create-assessment-modal";

const Assessments = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clearAssessmentFields, setClearAssessmentFields] = useState(false);
  const [assessment, setAssessment] = useState();

  useEffect(() => {
    // Fetch subjects on component mount
    const fetchSubjects = async () => {
      setIsLoading(true);
      try {
        const response = await axiosServices.get("/subjects");
        setSubjects(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubjects();
  }, []);

  const handleSubjectChange = async (event) => {
    setSelectedChapter("");
    const selectedSubject = event.target.value;
    setSelectedSubject(selectedSubject);

    // Fetch chapters based on selected subject
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/chapters?subjectId=${selectedSubject}`
      );
      let newChapters =
        response.data &&
        response.data.length &&
        response.data.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
      setChapters(newChapters);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAssessments = async (chapterId) => {
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/assessments?chapterId=${chapterId}`
      );
      setAssessments(response.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChapterChange = (event) => {
    const selectedChapter = event.target.value;
    setSelectedChapter(selectedChapter);
    getAssessments(selectedChapter);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
    setAssessment(false); //clear the edit assessment
  };

  const handleAssessmentCreate = async (newAssessment) => {
    newAssessment.chapterId = selectedChapter;
    if (newAssessment.isUpdate) {
      // edit assessment
      try {
        setIsLoading(true);
        const response = await axiosServices.put(`/assessments`, newAssessment);
        if (response.status == "200") {
          getAssessments(selectedChapter);
          setClearAssessmentFields(true);
        } else {
          alert("Error in creating assessment");
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      // add assessment
      try {
        setIsLoading(true);
        const response = await axiosServices.post(
          `/assessments`,
          newAssessment
        );
        if (response.status == "200") {
          getAssessments(selectedChapter);
          setClearAssessmentFields(true);
        } else {
          alert("Error in creating assessment");
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteAssessment = async (assessment) => {
    if (
      window.confirm(`Are you sure you want to delete ${assessment.title}?`)
    ) {
      try {
        setIsLoading(true);
        const response = await axiosServices.delete(
          `/assessments/${assessment.id}`
        );
        if (response.status == "200") {
          getAssessments(selectedChapter);
          setClearAssessmentFields(true);
        } else {
          alert("Error in deleting assessment");
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEditAssessment = async (assessment) => {
    setAssessment(assessment);
    handleModalOpen();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="subject-select-label">Subject</InputLabel>
              <Select
                labelId="subject-select-label"
                id="subject-select"
                value={selectedSubject}
                label="Subject"
                onChange={handleSubjectChange}
              >
                {subjects?.map((subject) => {
                  return (
                    <MenuItem key={subject.id} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {selectedSubject && (
          <Grid item>
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="chapter-select-label">Chapter</InputLabel>
                <Select
                  labelId="chapter-select-label"
                  id="chapter-select"
                  value={selectedChapter}
                  label="Chapter"
                  onChange={handleChapterChange}
                >
                  {chapters?.map((chapter) => {
                    return (
                      <MenuItem key={chapter.id} value={chapter.id}>
                        {chapter.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
      {selectedChapter && (
        <Box display={"flex"} alignItems={"center"} m={2}>
          <Typography variant="h6">Assessments</Typography>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={handleModalOpen}
            startIcon={<Add />}
          >
            Create
          </Button>
        </Box>
      )}
      <Box my={3}>
        {selectedChapter && (
          <>
            {assessments.length === 0 ? (
              <Typography variant="h6" align="center">
                No assessments available at the moment.
              </Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        <strong>Title</strong>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <strong>Description</strong>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <strong>Due Date</strong>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <strong>Timer Enabled</strong>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <strong>Actions</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assessments.map((assessment) => (
                      <TableRow key={assessment.id}>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.title}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.description}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {new Date(assessment.dueDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.enableTimer ? "Yes" : "No"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Grid
                            container
                            display={"flex"}
                            flexDirection={"row"}
                            spacing={2}
                            justifyContent={"center"}
                          >
                            <Grid item>
                              <IconButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditAssessment(assessment)}
                                aria-hidden
                              >
                                <Edit />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                variant="contained"
                                color="error"
                                onClick={() =>
                                  handleDeleteAssessment(assessment)
                                }
                                aria-hidden
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>
      <CreateAssessmentModal
        open={isModalOpen}
        handleClose={handleModalClose}
        handleCreate={handleAssessmentCreate}
        clearFields={clearAssessmentFields}
        assessment={assessment}
      />
    </>
  );
};

export default Assessments;
